import Vuex from "vuex";
import Vue from "vue";
import createPersistedState from "vuex-persistedstate";

import StoreGlobal from "./modules/StoreGlobal";
import StoreLogin from "./modules/StoreLogin";
import StorePaginacao from "./modules/StorePaginacao";

Vue.use(Vuex);

export default new Vuex.Store({
    modules: {
      StoreGlobal,
      StoreLogin,
      StorePaginacao,
    },
    plugins: [createPersistedState({
      paths: [
        'StoreGlobal',
        'StoreLogin',
        'StorePaginacao',
      ]
    })
    ]
  }
)
