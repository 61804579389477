const RouterInicio = [
  {
    path: "/quem-somos",
    component: () => import("@/views/dashboard/quem-somos/quem-somos"),
    meta: {
      requireAuth: true,
      layout: "layout-fullscreen",
      sideBarMenu: false,
      pageName: "Empresa",
      pageIcon: "pie-chart",
    },
  },
]

export default RouterInicio;