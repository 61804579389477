const RouterInicio = [
  {
    path: "/nossas-solucoes",
    component: () => import("@/views/dashboard/nossas-solucoes/nossas-solucoes"),
    meta: {
      requireAuth: true,
      layout: "layout-fullscreen",
      sideBarMenu: false,
      pageName: "Nossas soluções",
      pageIcon: "pie-chart",
    },
  },
  {
    path: "/nossas-solucoes/solucoes-em-tecnologia-da-informacao",
    component: () => import("@/views/dashboard/nossas-solucoes/solucoes-em-tecnologia-da-informacao"),
    meta: {
      requireAuth: true,
      layout: "layout-fullscreen",
      sideBarMenu: false,
      pageName: "Nossas soluções - Soluções em Tecnologia da Informação",
      pageIcon: "pie-chart",
    },
  },
  {
    path: "/nossas-solucoes/infraestrutura",
    component: () => import("@/views/dashboard/nossas-solucoes/infraestrutura"),
    meta: {
      requireAuth: true,
      layout: "layout-fullscreen",
      sideBarMenu: false,
      pageName: "Nossas soluções - Infraestrutura",
      pageIcon: "pie-chart",
    },
  },
  {
    path: "/nossas-solucoes/coworking",
    component: () => import("@/views/dashboard/nossas-solucoes/coworking"),
    meta: {
      requireAuth: true,
      layout: "layout-fullscreen",
      sideBarMenu: false,
      pageName: "Nossas soluções - Coworking",
      pageIcon: "pie-chart",
    },
  },
]

export default RouterInicio;