<template>
  <div>
    <v-img
      :gradient="!this.item.st_ativo ? 'to top right, rgba(230, 230, 230, 0.7), rgba(230, 230, 230, 0.7)' : ''"
      v-if="this.item.imagem !== null && this.item.imagem !== 'null'"
      :src="vueAppApiResources + '/' + this.item.imagem"
      :lazy-src="vueAppApiResources + '/' + this.item.imagem"
      :alt="this.item.nome"
      :width="this.size"
    ></v-img>
    <span v-else class="white--text text-h5">
      {{ this.item.nome.slice(0, 1).toUpperCase() }}
    </span>
  </div>
</template>

<script>
export default {
  props: ['item', 'size'],
  data: () => ({
    vueAppApiResources: process.env.VUE_APP_API_RESOURCES,
  })
}
</script>