<template>
  <v-app>
    <v-app-bar app light color="white" elevate-on-scroll elevation="1">
      <div class="d-flex black--text align-center">
        <v-app-bar-nav-icon @click="drawer = !drawer"></v-app-bar-nav-icon>
        <v-toolbar-title>{{ this.$route.meta.pageName }}</v-toolbar-title>
      </div>
      <v-spacer></v-spacer>
      <v-menu :close-on-content-click="false" :nudge-width="200" offset-x>
        <template v-slot:activator="{ on, attrs }">
          <v-avatar v-if="stateLogin.usuario.imagem !== null" size="40" v-bind="attrs" v-on="on">
            <img :src="vueAppApiResources + '/' + stateLogin.usuario.imagem" :alt="stateLogin.usuario.nome">
          </v-avatar>
          <v-avatar v-else color="grey lighten-3" size="40" v-bind="attrs" v-on="on">
            <v-icon light>
              mdi-camera-outline
            </v-icon>
          </v-avatar>
        </template>
        <v-card>
          <v-list>
            <v-list-item>
              <v-list-item-avatar :color="stateLogin.usuario.st_ativo ? constants.COLOR_PRIMARY : constants.COLOR_DISABLED">
                <Avatar :item="stateLogin.usuario" :size="40" />
              </v-list-item-avatar>
              <v-list-item-content>
                <v-list-item-title>{{ stateLogin.usuario.nome }}</v-list-item-title>
                <v-list-item-subtitle>{{ stateLogin.usuario.email }}</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </v-list>
          <v-divider></v-divider>
          <v-list>
            <v-list-item link @click="logout()">
              <v-list-item-title v-text="'Sair'"></v-list-item-title>
            </v-list-item>
          </v-list>
        </v-card>
      </v-menu>
    </v-app-bar>
    <v-navigation-drawer v-model="drawer" app>
      <v-sheet class="pa-4">
        <router-link to="/categorias">
          {{ constants.PROJECT }}
        </router-link>
        <br>
        <v-btn
          :color="constants.COLOR_PRIMARY"
          dark
          elevation="2" x-small to="/lojas">
          Teste
        </v-btn>
        <br>
        <small>Versão 1.0.0</small>
      </v-sheet>

      <v-divider></v-divider>

      <v-list>
        <v-list-item
            v-for="(link, key) in links"
            :key="key"
            link
            dense
            :to="link.route"
            :active-class="constants.COLOR_ACTIVE_CLASS"
        >
          <v-list-item-icon>
            <v-icon>{{ link.icon }}</v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title>{{ link.label }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>

    <v-main>
      <v-container
          class="py-8 px-6"
          fluid
      >
        <slot/>
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
import {Constants} from "@/constants/constants";
import {mapActions, mapGetters} from "vuex";

export default {
  computed: {
    ...mapGetters([
      "stateLogin",
    ]),
  },
  created() {
    this.$router.options.routes.forEach((route) => {
      if (route.meta.sideBarMenu) {
        this.items.push({
          path: route.path,
          name: route.name,
          meta: {
            guest: route.meta.guest,
            layout: route.meta.layout,
            pageName: route.meta.pageName,
            pageIcon: route.meta.pageIcon,
          },
        });
      }
    });
  },
  data: () => ({
    constants: Constants,
    vueAppApi: process.env.VUE_APP_API,
    vueAppApiResources: process.env.VUE_APP_API_RESOURCES,
    drawer: true,
    links: [
      {
        id: 2,
        label: 'Usuários',
        route: '/usuarios',
        icon: 'mdi-account-multiple-outline'
      },

      {
        id: 4,
        label: 'Compras',
        route: '/compras',
        icon: 'mdi-wallet-outline'
      },
      {
        id: 8,
        label: 'Pedidos',
        route: '/pedidos',
        icon: 'mdi-checkbox-multiple-marked-circle-outline'
      },
      {
        id: 9,
        label: 'Status',
        route: '/status',
        icon: 'mdi-checkbox-multiple-marked-circle-outline'
      },
      {
        id: 10,
        label: 'Formas de pagamento',
        route: '/formas-de-pagamento',
        icon: 'mdi-card-outline'
      },
      {
        id: 11,
        label: 'Locais de estoque',
        route: '/locais-de-estoque',
        icon: 'mdi-shopping-outline'
      },
      {
        id: 12,
        label: 'Dashboard',
        route: '/dashboard',
        icon: 'mdi-shopping-outline'
      },
    ]
  }),
  methods: {
    ...mapActions([
      "actionLogout",
    ]),
    logout() {
      this.actionLogout();
    },
  },
}
</script>