import Vue from 'vue'
import App from './App.vue'
import store from './store';
import router from './router/router';
import vuetify from './plugins/vuetify';
import VueNoty from 'vuejs-noty';
import { VueMaskDirective } from "v-mask";

import 'vuejs-noty/dist/vuejs-noty.css'
import Dashboard from "@/components/layouts/dashboard/dashboard";
import Fullscreen from "@/components/layouts/fullscreen/fullscreen";
import Blank from "@/components/layouts/blank/blank";
import Avatar from '@/components/avatar/avatar';
import SkeletonList from '@/components/skeleton-list/skeleton-list';
import 'vuejs-noty/dist/vuejs-noty.css'

Vue.component("layout-dashboard", Dashboard);
Vue.component("layout-fullscreen", Fullscreen);
Vue.component("layout-blank", Blank);
Vue.component("Avatar", Avatar);
Vue.component("SkeletonList", SkeletonList);
Vue.directive("mask", VueMaskDirective);
Vue.use(VueNoty);

Vue.config.productionTip = false

new Vue({
  vuetify,
  store,
  router,
  render: h => h(App)
}).$mount('#app')
