import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);
import RouterContato from "@/router/router-contato";
import RouterInicio from "@/router/router-inicio";
import RouterNossasSolucoes from "@/router/router-nossas-solucoes";
import RouterQuemSomos from "@/router/router-quem-somos";

const router = new VueRouter({
  routes: [
    ...RouterContato,
    ...RouterInicio,
    ...RouterNossasSolucoes,
    ...RouterQuemSomos,
    {
      path: "/",
      redirect: "/inicio",
      component: () => import("@/views/dashboard/inicio/inicio"),
      meta: {
        layout: "layout-fullscreen",
        sideBarMenu: false,
        pageName: "Início",
        pageIcon: "lock",
      },
    },
  ],
});

export default router;
