<template>
  <v-app id="inspire">
    <v-app-bar
      app
      color="white"
      dense
      elevation="1"
      elevate-on-scroll
    >
      <v-container class="py-0 fill-height">
        <router-link to="/inicio" class="title text-decoration-none black--text font-weight-bold">
          {{ constants.PROJECT }}
        </router-link>
        <v-spacer></v-spacer>
        <v-menu offset-y left v-for="(item, key) in links" :key="key">
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              :to="item.route"
              text
              small
              :active-class="constants.COLOR_ACTIVE_CLASS"
              class="ml-2 font-weight-bold"
              v-bind="attrs"
              v-on="on"
            >
              {{ item.label }}
            </v-btn>
          </template>
          <v-list v-if="item.children">
            <v-list-item :to="item.route" v-for="(item, key) in item.children" :key="key">
              <v-list-item-title>{{ item.label }}</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
      </v-container>
    </v-app-bar>

    <v-main>
      <slot />
      <v-card dark flat tile :color="constants.COLOR_PRIMARY">
        <v-container>
          <v-row class="d-flex align-center pt-8 pb-8">
            <v-col>
              <h2>Receba as novidades do Grupo Manatus por e-mail</h2>
            </v-col>
            <v-col cols="4">
              <v-btn
                dark
                elevation="2"
                block
                :color="constants.COLOR_SUCCESS">
                Assinar
              </v-btn>
            </v-col>
          </v-row>
        </v-container>
      </v-card>
    </v-main>
    <Footer/>
  </v-app>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import {Constants} from "@/constants/constants";
import Footer from "./footer";

export default {
  components: {
    Footer,
  },
  computed: {
    ...mapGetters([
      "stateLogin",
    ]),
  },
  data: () => ({
    constants: Constants,
    vueAppApiResources: process.env.VUE_APP_API_RESOURCES,
    links: [
      {
        label: 'Início',
        route: '/inicio'
      },
      {
        label: 'Quem Somos',
        route: '/quem-somos'
      },
      {
        label: 'Nossas Soluções',
        children: [
          {
            label: 'Soluções em Tecnologia da Informação',
            route: '/nossas-solucoes/solucoes-em-tecnologia-da-informacao',
          },
          {
            label: 'Infraestrutura',
            route: '/nossas-solucoes/infraestrutura',
          },
          {
            label: 'Coworking',
            route: '/nossas-solucoes/coworking',
          },
        ]
      },
      {
        label: 'Contato',
        route: '/contato'
      },
      {
        label: 'Área do Cliente',
        route: '/'
      },
    ],
  }),
  methods: {
    ...mapActions([
      "actionLogout",
    ]),
    logout() {
      this.actionLogout();
    },
  },
};
</script>
