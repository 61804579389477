<template>
  <v-app>
    <v-main>
      <slot/>
    </v-main>
  </v-app>
</template>

<script>
import {Constants} from "@/constants/constants";

export default {
  name: "app",
  data() {
    return {
      projeto: Constants.PROJECT,
      verde: Constants.VERDE,
      azul: Constants.AZUL,
      preto: Constants.PRETO,
      constants: Constants,
    };
  },
};
</script>
