const getDefaultStates = () => {
  return {
    stateLoadingPrimeiraVisita: true,
    stateGlobalFuncionarioSelecionado: {},
    stateGlobalMesSelecionado: {},
    stateGlobalConfirmarExclusao: false,
  };
};
export default {
  state: getDefaultStates(),
  getters: {
    stateLoadingPrimeiraVisita: (state) => state.stateLoadingPrimeiraVisita,
    stateGlobalFuncionarioSelecionado: (state) => state.stateGlobalFuncionarioSelecionado,
    stateGlobalMesSelecionado: (state) => state.stateGlobalMesSelecionado,
    stateGlobalConfirmarExclusao: (state) => state.stateGlobalConfirmarExclusao,
  },
  mutations: {
    _setGlobalLoadingPrimeiraVisita(state, data) {
      state.stateLoadingPrimeiraVisita = data;
    },
    _setGlobalFuncionarioSelecionado(state, data) {
      state.stateGlobalFuncionarioSelecionado = data;
    },
    _setGlobalMesSelecionado(state, data) {
      state.stateGlobalMesSelecionado = data;
    },
    _setGlobalConfirmarExclusao(state, data) {
      state.stateGlobalConfirmarExclusao = data;
    },
    _resetState(state) {
      Object.assign(state, getDefaultStates());
    },
  },
  actions: {
    actionSetGlobalLoadingPrimeiraVisita({commit}, primeiraVisita) {
      commit("_setGlobalLoadingPrimeiraVisita", primeiraVisita);
    },
    actionSetGlobalFuncionarioSelecionado({commit}, funcionario) {
      commit("_setGlobalFuncionarioSelecionado", funcionario);
    },
    actionSetGlobalMesSelecionado({commit}, mes) {
      commit("_setGlobalMesSelecionado", mes);
    },
  },
};
