<template>
  <v-footer color="#1b1b1b" dark>
    <v-container class="pt-16 pb-16">
      <v-card flat tile class="transparent">
        <v-row>
          <v-col>
            <h2>{{ constants.PROJECT }}</h2>
            <small>
              O Grupo Manatus, criada em 2003, sediada em Brasília-DF, desenvolve soluções em tecnologia da informação para o setor público e privado, atuando principalmente no mercado de saúde, engenharia, embaixadas e escritórios de representações de países.
            </small>
          </v-col>
          <v-col cols="2">
            <h4>Quem somos</h4> <br>
            <v-btn text block x-small class="justify-start mb-2" to="/empresa">
              Empresa
            </v-btn>
            <v-btn text block x-small class="justify-start mb-2" to="/missao">
              Missão
            </v-btn>
            <v-btn text block x-small class="justify-start mb-2" to="/visao">
              Visão
            </v-btn>
            <v-btn text block x-small class="justify-start mb-2" to="/valores">
              Valores
            </v-btn>
            <v-btn text block x-small class="justify-start mb-2" to="/equipe">
              Equipe
            </v-btn>
          </v-col>
          <v-col cols="3">
            <h4>Nossas Soluçôes</h4> <br>
            <v-btn text block x-small class="justify-start mb-2" to="/nossas-solucoes">
              Soluções em Tecnologia da Informação
            </v-btn>
            <v-btn text block x-small class="justify-start mb-2" to="/nossas-solucoes">
              Infraestrutura
            </v-btn>
            <v-btn text block x-small class="justify-start mb-2" to="/nossas-solucoes">
              Coworking
            </v-btn>
          </v-col>
          <v-col cols="2">
            <h4>Contato</h4> <br>
            <v-btn text block x-small class="justify-start mb-2">
              Telefone
            </v-btn>
            <v-btn text block x-small class="justify-start mb-2">
              contato@manatus.com.br
            </v-btn>
            <v-btn text block x-small class="justify-start mb-2">
              Endereço
            </v-btn>
            <v-btn text block x-small class="justify-start mb-2">
              Mídias sociais
            </v-btn>
            <v-btn text block x-small class="justify-start mb-2">
              Trabalhe conosco
            </v-btn>
          </v-col>
        </v-row>
        <v-row>
          <v-spacer></v-spacer>
          <v-col cols="2">
            <span>Área do cliente</span> <br>
            <v-btn
              dark
              elevation="2"
              x-small
              block
              :color="constants.COLOR_PRIMARY">
              Acessar
            </v-btn>
          </v-col>
          <v-col cols="2">
            <span>Clientes & Parceiros</span> <br>
            <v-carousel height="150"
              show-arrows-on-hover
              hide-delimiter-background
              hide-delimiters>
              <v-carousel-item v-for="(color, i) in 5" :key="color">
                <v-sheet :color="constants.COLOR_PRIMARY" height="150" tile>
                  <v-row class="fill-height" align="center" justify="center">
                    <div class="text-h4">
                      Cliente {{ i + 1 }}
                    </div>
                  </v-row>
                </v-sheet>
              </v-carousel-item>
            </v-carousel>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-card-text class="pt-0 text-center">
              <h2>{{ constants.PROJECT }}</h2>
            </v-card-text>
          </v-col>
        </v-row>
        <v-card-text class="text-center">
          <v-btn
            v-for="icon in icons"
            :href="icon.link"
            target="_blank"
            :key="icon.id"
            class="mx-4"
            icon
          >
            <v-img :src="icon.image" width="30"></v-img>
          </v-btn>
        </v-card-text>
        <v-card-text class="text-center">
          {{ new Date().getFullYear() }} — {{ constants.PROJECT }} - Todos os direitos reservados
        </v-card-text>
        <div class="text-center">
          <small>
            Somos uma empresa que acredita que a tecnologia deve estar aliada às
            boas práticas de negócios voltadas para sustentabilidade, Processos
            eficientes colaboram ativamente para redução de insumos, racionalizam
            o consumo exacerbado de energia e colaboram na proteção do
            meio ambiente.
          </small>
        </div>
      </v-card>
    </v-container>
  </v-footer>
</template>

<script>
import { Constants } from "@/constants/constants";
import Whatsapp from "@/assets/img/whatsapp.png";
import Instagram from "@/assets/img/instagram.png";
import Facebook from "@/assets/img/facebook.png";
import {mapGetters} from "vuex";

export default {
  computed: {
    ...mapGetters([
      "stateGlobalEmpresaSelecionada",
    ]),
  },
  data() {
    return {
      icons: [
        {
          id: 1,
          image: Whatsapp,
          link: "/",
        },
        {
          id: 2,
          image: Instagram,
          link: "/",
        },
        {
          id: 3,
          image: Facebook,
          link: "/",
        },
      ],
      constants: Constants,
    };
  },
};
</script>
