export const Constants = {
  PROJECT: "Grupo Manatus",
  PROJECT_DESCRIPTION: "Descrição aqui",
  COLOR_PRIMARY: '#21709e',
  COLOR_SECONDARY: '#fbba00',
  COLOR_DANGER: '#ff5252',
  COLOR_SUCCESS: '#8bc34a',
  COLOR_DISABLED: '#bdbdbd',
  COLOR_ACTIVE_CLASS: 'light-blue--text text--darken-4',
  COLOR_BACKGROUND: '#e9f3f8',
};

export default Constants;
