import axios from "axios";
import jwt_decode from "jwt-decode";
import router from "@/router/router";
import {Notification, NotificationError} from "@/util/noty";

const getDefaultStates = () => {
  return {
    stateLogin: [],
    stateLoadingLogin: false,
  };
};

export default {
  state: getDefaultStates(),
  getters: {
    stateLogin: (state) => state.stateLogin,
    stateLoadingLogin: (state) => state.stateLoadingLogin,
  },
  mutations: {
    _setLogin(state, data) {
      state.stateLogin = data;
    },
    _setLoadingLogin(state, data) {
      state.stateLoadingLogin = data
    },
    _resetState(state) {
      Object.assign(state, getDefaultStates());
    },
  },
  actions: {
    actionLogin({commit}, data) {
      commit("_setLoadingLogin", true);
      axios
        .post(
          `${process.env.VUE_APP_API}/admin/u/auth/login`,
          {
            email: data.email,
            senha: data.senha,
          }
        )
        .then((response) => {
          if (response.data.success) {
            commit("_setLogin", jwt_decode(response.data.token));
            localStorage.setItem('token', response.data.token);
            commit("_setLoadingLogin", false);
            router.push("/inicio");
          } else {
            Notification(
              "danger",
              response.data.mensagem.titulo,
              response.data.mensagem.descricao
            );
            commit("_setLoadingLogin", false);
          }
        })
        .catch((error) => {
          NotificationError();
          commit("_setLoadingLogin", false);
          throw error.response.data;
        })
        .finally(() => {
          commit("_setLoadingLogin", false);
        });
    },
    actionLogout({ commit }) {
      commit(`_resetState`);
      router.push("/login");
    }
  },
};
