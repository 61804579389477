import Noty from 'noty'
import 'vuejs-noty/dist/vuejs-noty.css'

export function Notification(type, title, description) {
  new Noty({
    type: 'alert',
    theme: 'sunset',
    layout: 'topRight',
    text: `
      <h5 class='m-0'><span class='badge badge-${type}'>${title}</span></h5>
      <p class='mt-2'>${description}</p>
    `,
    timeout: 5000,
  }).show()
}

export function NotificationError() {
  new Noty({
    type: 'alert',
    theme: 'sunset',
    layout: 'topRight',
    text: `
      <h5 class='m-0'><span class='badge badge-danger'>Ooops!</span></h5>
      <p class='mt-2'>Aconteceu algum problema. Por favor, tente novamente.</p>
    `,
    timeout: 5000,
  }).show()
}
