<template>
  <span>
    <div v-if="Number(statePaginacao.size) > 15">
      <div v-for="(item, key) in 15" :key="key">
        <v-skeleton-loader
          style=""
          type="list-item-avatar-two-line">
        </v-skeleton-loader>
        <v-divider v-if="key < Number(statePaginacao.size) - 1"></v-divider>
      </div>
    </div>
    <div v-else>
      <div v-for="(item, key) in Number(statePaginacao.size)" :key="key">
        <v-skeleton-loader
          style=""
          type="list-item-avatar-two-line">
        </v-skeleton-loader>
        <v-divider v-if="key < Number(statePaginacao.size) - 1"></v-divider>
      </div>
    </div>
  </span>
</template>

<script>
import {mapGetters} from "vuex";

export default {
  computed: {
    ...mapGetters([
      "statePaginacao",
    ]),
  },
};
</script>

<style>
.v-skeleton-loader__list-item-avatar-two-line {
  height: 60px !important;
}
</style>