const getDefaultStates = () => {
  return {
    statePaginacao: {
      page: 1,
      size: 5
    },
    statePaginacaoQuantidade: [
      {
        label: '5',
        value: 5
      },
      {
        label: '10',
        value: 10
      },
      {
        label: '15',
        value: 15
      },
      {
        label: '20',
        value: 20
      },
    ],
  };
};
export default {
  state: getDefaultStates(),
  getters: {
    statePaginacao: (state) => state.statePaginacao,
    statePaginacaoQuantidade: (state) => state.statePaginacaoQuantidade,
  },
  mutations: {
    _setPaginacao(state, data) {
      state.statePaginacao = data;
    },
    _resetState(state) {
      Object.assign(state, getDefaultStates());
    },
  },
  actions: {
    actionAlterarPaginacao({commit}, size) {
      commit("_setPaginacao", {
        page: this.state.StorePaginacao.statePaginacao.page,
        size
      });
    },
  },
};
