<template>
  <component :is="layout">
    <div v-if="ambiente !== ''" style="position: fixed; left: 10px; bottom: 15px; z-index: 888">
      <v-chip :color="ambiente === 'LOCALHOST' ? '' : 'orange'">
        Ambiente {{ ambiente }}
      </v-chip>
    </div>
    <router-view></router-view>
  </component>
</template>

<script>
export default {
  name: 'App',
  computed: {
    layout() {
      return this.$route.meta.layout;
    },
  },
  data() {
    return {
      ambiente: ''
    }
  },
  mounted() {
    this.ambiente = String(window.location.hostname).toUpperCase();
    if (this.ambiente.indexOf("LOCALHOST") != -1) {
      this.ambiente = 'LOCALHOST'
    } else if (this.ambiente.indexOf("STG") != -1) {
      this.ambiente = 'STAGING'
    } else {
      this.ambiente = '';
    }
  }
};
</script>

